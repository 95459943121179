import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import Banner from "../components/banner";

const IndexPage = () => (
  <Layout>
    <Seo title="Programm" />
    <Banner illustration={
      <StaticImage
          src={`../images/illustrations/Cheers.png`}
          loading="eager"
          height={128}
          quality={98}
          formats={["auto", "webp", "avif"]}
          alt="Love"
      />
    } title="Programm" />
    <div>
      <table>
        <tr>
          <td />
          <td className="title">
              <strong>Teil 1 (Nur geladene Gäste)</strong>
          </td>
        </tr>
        <tr>
          <td><code>14.30</code></td>
          <td>Empfang in der <a
              className={styles.listItemLink}
              href={`https://goo.gl/maps/zkuZoetsy5jeQWzr9`}
          >
            Weinschenke vom Hotel Hirschen
          </a>. Eingang via Hirschengasse. Tramstation: <a href={'https://goo.gl/maps/ppY5qBTi8U6Bzttb7'}>Rudolf-Brun-Brücke</a></td>
        </tr>
        <tr>
          <td><code>15.00</code></td>
          <td>Zivile Trauung mit anschliessendem Apéro</td>
        </tr>
        <tr>
          <td><code>17.30</code></td>
          <td>Ende Teil 1 & individuelle Abreise. Bitte kein Konfetti.</td>
        </tr>
        <tr>
          <td />
          <td className="title">
              <strong>Teil 2</strong>
          </td>
        </tr>
        <tr>
          <td><code>18.00</code></td>
          <td>Eintreffen @ <a
              className={styles.listItemLink}
              href={`https://goo.gl/maps/c5x7jtN6Vof6TAo3A`}
          >
            Fork & Bottle
          </a></td>
        </tr>
        <tr>
          <td><code>18.30</code></td>
          <td>Starters</td>
        </tr>
        <tr>
          <td><code>19.30</code></td>
          <td>Family Style Dinner</td>
        </tr>
        <tr>
          <td><code>21.30</code></td>
          <td>Dessert</td>
        </tr>
        <tr>
          <td><code>22.00</code></td>
          <td>Open End Party (<Link to="/party">Musik zur Playlist hinzufügen</Link>)</td>
        </tr>
      </table>
    </div>

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Programm" />

export default IndexPage
